<template>
  <div class="row d-inline mx-0">
    <div class="card card-custom gutter-b" style="min-height: 85px">
      <div class="card-body px-7 pt-2 pb-7">
        <div
          class="d-flex justify-content-center mx-0"
          v-if="!loadingComplete && !error.active"
        >
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>
        </div>
        <div v-if="loadingComplete && !error.active">
          <div class="d-flex justify-content-start my-2">
            <p class="my-1 py-2 px-3 font-weight-bolder">
              {{ $t("GENERAL.PROJECT_COMMUNICATE_FILTER.RECIPIENTS") }}
            </p>
          </div>

          <InputGenericDropdown
            id="input-email-type"
            class="col-12 mb-0 px-0"
            :model="filters.type"
            :options="supportedTypes"
            optionLabel="title"
            value="id"
            :disabled="!allowUpdate"
          >
          </InputGenericDropdown>

          <!--start::Register Select (if type != 3, 4 or 5)-->
          <InputGenericTreeSelect
            v-if="
              ![3,4,5].includes(filters.$model.type) &&
              allowUpdate
            "
            id="input-register-type"
            class="my-5"
            :model="register_status"
            :placeholder="$t('GENERAL.PROJECT_USER_FILTER.STATUS_LABEL')"
            :showInline="false"
            :submissionStates="submissionStates"
            :options="computedRegisterStatusOptions"
            :overrides="{ valueFormat: 'LEAF_PRIORITY' }"
          >
          </InputGenericTreeSelect>
          <!--end::Segment Select-->

          <!--start::Segment Select (If type 2 or 3)-->
          <InputGenericTreeSelect
            v-if="
              (filters.$model.type == 2 || filters.$model.type == 3) &&
              allowUpdate
            "
            id="input-segments"
            class="my-5"
            :model="filters.segments"
            :placeholder="$t('GENERAL.PROJECT_COMMUNICATE_FILTER.SEGMENTS')"
            :showInline="false"
            :submissionStates="submissionStates"
            :options="segmentationBreakdown"
            :overrides="{ valueFormat: 'LEAF_PRIORITY' }"
          >
          </InputGenericTreeSelect>
          <!--end::Segment Select-->

          <!--start::User Select (If type 4 or 5 or 3 if segment array length is > 1)-->
          <InputGenericTreeSelect
            v-if="
              (filters.$model.type == 3 ||
                filters.$model.type == 4 ||
                filters.$model.type == 5) &&
              allowUpdate
            "
            id="input-users"
            class="my-5"
            :model="filters.users"
            :placeholder="UserPlaceholder"
            :showInline="false"
            :submissionStates="submissionStates"
            :options="computedProfiles"
            :overrides="{ valueFormat: 'BRANCH_PRIORITY' }"
          >
          </InputGenericTreeSelect>
          <!--end::User Select-->

          <!--start::Activity Select (If type 6 or 7-->
          <InputGenericDropdown
            v-if="
              (filters.$model.type == 6 || filters.$model.type == 7) &&
              allowUpdate
            "
            id="input-email-type"
            class="col-12 my-5 px-0"
            :placeholder="$t('FORMS.PLACEHOLDER.ACTIVITY')"
            :model="filters.activity_id"
            :options="activityList"
            optionLabel="title"
            value="id"
            :disabled="!allowUpdate"
          >
          </InputGenericDropdown>
          <!--end::Activity Select-->

          <div class="d-flex justify-content-end mt-10">
            <button class="btn btn-secondary px-7" @click="onReset">
              {{ $t("REVIEW.TEXT_ANALYSIS.RESET") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown.vue";
import InputGenericTreeSelect from "@/view/components/form-inputs/GenericTreeSelect.vue";

export default {
  name: "ParticipantFilter",

  props: {
    loadingComplete: { type: Boolean, required: true },
    submissionStates: { type: Object, required: true },
    error: { type: Object, required: true },
    registerStatus: { type: Array, required: true },
    supportedTypes: { type: Array, required: true },
    segmentationBreakdown: { type: Array, required: true },
    profileBreakdown: { type: Array, required: true },
    activityList: { type: Array, required: true },
    filters: { type: Object, required: true },
    register_status: { type: Object, required: true },
    allowUpdate: { type: Boolean, required: true },
  },

  components: {
    InputGenericDropdown,
    InputGenericTreeSelect
  },

  methods: {
    onReset: function () {
      this.$emit("reset_filters");
    },
  },

  computed: {
    computedRegisterStatusOptions: function() {
      return [
        { id: 4, label: "Pending Invite" },
        { id: 2, label: "Invite Sent / Profile Incomplete" },
        { id: 1, label: "Profile Complete" },
        { id: 5, label: "Project Blocked" },
        { id: 3, label: "System Blocked" }
      ];
    },
    computedProfiles: function () {
      let users = [];
      let selectedSegments = this.filters.$model.segments;

      if (this.filters.$model.type == 4) {
        users = this.profileBreakdown.filter(function (obj) {
          return obj.role == 3;
        });
      } else if (this.filters.$model.type == 5) {
        users = this.profileBreakdown.filter(function (obj) {
          return obj.role == 2;
        });
      } else if (
        this.filters.$model.type == 3 &&
        this.filters.$model.segments.length == 0
      ) {
        users = this.profileBreakdown.filter(function (obj) {
          return obj.role == 1;
        });
      } else {
        users = this.profileBreakdown.filter(function (obj) {
          return (
            obj.role == 1 &&
            obj.segments.filter(function (segment) {
              return selectedSegments.includes(segment.id);
            }).length > 0
          );
        });
      }

      return users;
    },
    UserPlaceholder: function () {
      let placeholder = null;

      if (this.filters.$model.type == 4) {
        placeholder = `${this.$t(
          "GENERAL.PROJECT_COMMUNICATE_FILTER.COMMUNITY_MANAGERS"
        )}`;
      } else if (this.filters.$model.type == 5) {
        placeholder = `${this.$t(
          "GENERAL.PROJECT_COMMUNICATE_FILTER.OBSERVERS"
        )}`;
      } else if (this.filters.$model.type == 3) {
        placeholder = `${this.$t(
          "GENERAL.PROJECT_COMMUNICATE_FILTER.PARTICIPANTS"
        )}`;
      }

      return placeholder;
    },
  },
};
</script>
