<template>
  <b-modal
    id="email-preview-modal"
    size="xl"
    ref="modal"
    :hide-footer="true"
    :hide-header="true"
    @show="loadPreview"
    body-class="pl-20"
  >
    <div
      v-if="loadingComplete && !errorsPresent"
      v-html="projectCommunicationPreview"
    >
    </div>

    <b-spinner
      v-if="!loadingComplete && !errorsPresent"
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>

    <b-alert v-if="loadingComplete && errorsPresent" variant="danger" class="m-5" show>{{ error }}</b-alert>

  </b-modal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

import PageLoader from "@/core/classes/PageLoader.js";

export default {
  name: 'EmailPreview',

  props: {
    form: { type: Object, required: true }
  },

  data() {
    return {
      pageLoader: new PageLoader(0),
      error: null
    }
  },

  mounted() {
    this.error = null;
  },

  methods: {
    loadPreview: function() {
      this.pageLoader.addRequiredComponent();

      let payload = {
        projectId: this.projectInfo.id,
        params: {
          include_branding: this.form.include_branding,
          type: this.form.type,
          segments: this.form.segments,
          profiles: this.form.profiles,
          activity_id: this.form.activity_id,
          subject: this.form.subject,
          content: this.form.content,
        }
      }
      this.populatePreview(payload).catch(() => { this.error = "Unable to generate preview"}).finally(() => { this.pageLoader.setCompleteComponent(); });
    },
    ...mapActions({
      populatePreview: "populateProjectCommunicationPreview"
    }),
    ...mapMutations({
      setProjectCommunicationPreviewError: "setProjectCommunicationPreviewError"
    })
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectCommunicationPreview",
      "projectCommunicationPreviewError"
    ]),

    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },

    errorsPresent: function() {
      return (this.error == null) ? false : true;
    }
  }
};
</script>