import { POPULATE_PROJECT_COMMUNICATIONS_TEMPLATE_INFO } from "@/core/services/store/project/project_communications.module";

class EmailTemplateHelper {

  static alertTagsInvalid(component) {
    component.$swal.fire({
      title: `${component.$t("ALERTS.EMAIL_TEMPLATE.TAGS_INVALID_TITLE")}`,
      text: `${component.$t("ALERTS.EMAIL_TEMPLATE.TAGS_INVALID_CONTENT")}`,
      icon: 'error',
      confirmButtonText: `${component.$t("ALERTS.GENERAL.CONFIRM")}`
    })
  }

  static alertPlaceholderPresent(component) {
    component.$swal.fire({
      title: `${component.$t("ALERTS.EMAIL_TEMPLATE.PLACEHOLDER_PRESENT_TITLE")}`,
      text: `${component.$t("ALERTS.EMAIL_TEMPLATE.PLACEHOLDER_PRESENT_CONTENT")}`,
      icon: 'error',
      confirmButtonText: `${component.$t("ALERTS.GENERAL.CONFIRM")}`
    })
  }

  static async warnChange(component) {
    return await new Promise((resolve, reject) => {
      component.$swal.fire({
        title: `${component.$t("ALERTS.EMAIL_TEMPLATE.REPLACE_TEMPLATE_TITLE")}`,
        text: `${component.$t("ALERTS.EMAIL_TEMPLATE.REPLACE_TEMPLATE_BODY")}`,
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: `${component.$t("ALERTS.GENERAL.CONFIRM")}`,
        cancelButtonText: `${component.$t("ALERTS.GENERAL.CANCEL")}`,
      }).then((result) => {
        resolve(result.isConfirmed)
      })
    })
  }

  static async fetchContent(component, templateId) {
    return await new Promise((resolve) => {
      let payload = {
        projectId: component.projectInfo.id,
        id: templateId,
        params: {
          paginate: false
        }
      };
      component.$store.dispatch(POPULATE_PROJECT_COMMUNICATIONS_TEMPLATE_INFO, payload).then((res) => {
        resolve(res);
      });
    })   
  }

  /**
   * Checks the below conditions are true
   * 1. If the form.content contains activity related tags then ensure activityId is provided in the form
   * 
   * @param {object} form
   * @return Bool 
   */
  static dataValidForTemplate(form) {
    return ((form.content.includes("#activity_") && form.activity_id == null)) ? false : true; 
  }

  /**
   * Checks the below conditions are true
   * 1. If the form.content contains the string "[INSERT_VALUE]" 
   * 
   * @param {object} form
   * @return Bool
   */
  static dateDoesNotContainPlaceholder(form) {
    return (form.content.includes("[INSERT_VALUE]")) ? false : true;
  }
  
}
  
export default EmailTemplateHelper;