<template>
  <b-modal size="xl" ref="modal">
    <template #modal-title>
      {{ $t('MODALS.EMAIL_VARIABLES.TITLE') }}
    </template>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <button @click="$refs.modal.hide()" class="btn btn-outline-primary mr-3">
          Cancel
        </button>
      </div>
    </template>
    <div>
      <table class="table table-head-custom table-vertical center table-hover">
        <thead>
          <tr>
            <th scope="col" style="width:35%">{{ $t('TABLES.EMAIL_VARIABLES.KEY') }}</th>
            <th scope="col">{{ $t('TABLES.EMAIL_VARIABLES.VALUE') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(variable, v) in variables">
            <tr :key="v">
              <td>#{{ variable.key }}#</td>
              <td>{{ $t(variable.value) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'EmailVariables',

  props: {
    variables: { type: Array, required: false }
  },

  methods: {

  },

  computed: {

  }
};
</script>
