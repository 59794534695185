var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-inline mx-0"},[_c('div',{staticClass:"card card-custom gutter-b",staticStyle:{"min-height":"85px"}},[_c('div',{staticClass:"card-body px-7 pt-2 pb-7"},[(!_vm.loadingComplete && !_vm.error.active)?_c('div',{staticClass:"d-flex justify-content-center mx-0"},[_c('b-spinner',{staticClass:"table-loader my-3",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),(_vm.loadingComplete && !_vm.error.active)?_c('div',[_c('div',{staticClass:"d-flex justify-content-start my-2"},[_c('p',{staticClass:"my-1 py-2 px-3 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.PROJECT_COMMUNICATE_FILTER.RECIPIENTS"))+" ")])]),_c('InputGenericDropdown',{staticClass:"col-12 mb-0 px-0",attrs:{"id":"input-email-type","model":_vm.filters.type,"options":_vm.supportedTypes,"optionLabel":"title","value":"id","disabled":!_vm.allowUpdate}}),(
            ![3,4,5].includes(_vm.filters.$model.type) &&
            _vm.allowUpdate
          )?_c('InputGenericTreeSelect',{staticClass:"my-5",attrs:{"id":"input-register-type","model":_vm.register_status,"placeholder":_vm.$t('GENERAL.PROJECT_USER_FILTER.STATUS_LABEL'),"showInline":false,"submissionStates":_vm.submissionStates,"options":_vm.computedRegisterStatusOptions,"overrides":{ valueFormat: 'LEAF_PRIORITY' }}}):_vm._e(),(
            (_vm.filters.$model.type == 2 || _vm.filters.$model.type == 3) &&
            _vm.allowUpdate
          )?_c('InputGenericTreeSelect',{staticClass:"my-5",attrs:{"id":"input-segments","model":_vm.filters.segments,"placeholder":_vm.$t('GENERAL.PROJECT_COMMUNICATE_FILTER.SEGMENTS'),"showInline":false,"submissionStates":_vm.submissionStates,"options":_vm.segmentationBreakdown,"overrides":{ valueFormat: 'LEAF_PRIORITY' }}}):_vm._e(),(
            (_vm.filters.$model.type == 3 ||
              _vm.filters.$model.type == 4 ||
              _vm.filters.$model.type == 5) &&
            _vm.allowUpdate
          )?_c('InputGenericTreeSelect',{staticClass:"my-5",attrs:{"id":"input-users","model":_vm.filters.users,"placeholder":_vm.UserPlaceholder,"showInline":false,"submissionStates":_vm.submissionStates,"options":_vm.computedProfiles,"overrides":{ valueFormat: 'BRANCH_PRIORITY' }}}):_vm._e(),(
            (_vm.filters.$model.type == 6 || _vm.filters.$model.type == 7) &&
            _vm.allowUpdate
          )?_c('InputGenericDropdown',{staticClass:"col-12 my-5 px-0",attrs:{"id":"input-email-type","placeholder":_vm.$t('FORMS.PLACEHOLDER.ACTIVITY'),"model":_vm.filters.activity_id,"options":_vm.activityList,"optionLabel":"title","value":"id","disabled":!_vm.allowUpdate}}):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end mt-10"},[_c('button',{staticClass:"btn btn-secondary px-7",on:{"click":_vm.onReset}},[_vm._v(" "+_vm._s(_vm.$t("REVIEW.TEXT_ANALYSIS.RESET"))+" ")])])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }