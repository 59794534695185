<template>
  <div
    class="d-flex row justify-content-between"
    style="margin: 0px -7px !important"
  >
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 px-2">
      <ParticipantFilter
        :loadingComplete="loadingComplete"
        :submissionStates="submissionStates"
        :error="error"
        :supportedTypes="supportedTypes"
        :registerStatus="registerStatus"
        :segmentationBreakdown="segmentationBreakdown"
        :profileBreakdown="profileBreakdown"
        :activityList="activityList"
        :filters="$v.form"
        :register_status="$v.register_status"
        :allowUpdate="allowUpdate"
        @reset_filters="onFilterReset"
      >
      </ParticipantFilter>
    </div>

    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9 px-2">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div v-if="loadingComplete" class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              {{ $t("FORMS.MANAGE_COMMUNICATION.TITLE") }}
              <span
                class="label label-pill label-inline ml-1"
                :class="'label-' + communicationStage.class"
              >
                {{ communicationStage.text }}
              </span>
            </h3>
          </div>
        </div>
        <div class="card-body">
          <b-alert
            v-if="
              loadingComplete &&
              Object.keys(projectCommunicationErrors).length == 0 &&
              moaValidation == false
            "
            class="mb-10"
            show
            variant="danger"
          >
            <span>{{ $t("FORMS.MANAGE_COMMUNICATION.MOA_VALIDATION_1") }}</span>

            <router-link
              :to="{ name: 'setup_theme', params: { tab: 3 } }"
              class="text-white mx-1 font-weight-bolder"
              style="text-decoration: underline"
            >
              {{ $t("GENERAL.TEXT.CLICK") }}
            </router-link>

            <span>{{ $t("FORMS.MANAGE_COMMUNICATION.MOA_VALIDATION_2") }}</span>
          </b-alert>

          <ErrorBox
            :errors="projectCommunicationErrors"
            @clear_errors="clearErrors"
          >
          </ErrorBox>
          <b-spinner
            v-if="loadingComplete == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>
          <div v-if="loadingComplete && !error.active">
            <div class="row px-0">
              <InputGenericCheckbox
                class="col-2 mb-5"
                id="input-communication-draft"
                :label="$t('SYSTEM.SENT_STATUS.DRAFT')"
                :model="$v.form.draft"
                :submissionStates="submissionStates"
              >
              </InputGenericCheckbox>
              <InputGenericCheckbox
                class="col-2 mb-5"
                id="input-communication-branding"
                :label="$t('FORMS.MANAGE_COMMUNICATION.INCLUDE_BRANDING')"
                :model="$v.form.include_branding"
                :submissionStates="submissionStates"
              >
              </InputGenericCheckbox>
            </div>
            <div class="row mb-5">
              <InputGenericDropdown
                id="input-template-select"
                class="col-xl-6 mb-5"
                :label="$t('FORMS.INPUTS.TEMPLATE')"
                :model="$v.form.template_id"
                :options="projectCommunicationTemplateList"
                :placeholder="calculatedDropdownPlaceholder"
                optionLabel="name"
                value="id"
                :disabled="
                  !allowUpdate || projectCommunicationTemplateList.length == 0
                "
              >
              </InputGenericDropdown>
              <div class="d-flex align-items-center">
                <button
                  class="btn btn-sm btn-secondary mx-1 h-30px mt-3"
                  @click="onVariablesToggle()"
                >
                  {{ $t("GENERAL.BUTTONS.EMAIL_VARIABLES") }}
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <InputGenericDatePicker
                id="input-delivery-date"
                class="col-xl-6"
                :model="$v.form.delivery_date"
                :submissionStates="submissionStates"
                :label="$t('FORMS.INPUTS.DELIVERY_DATE')"
                type="datetime"
                :storedAsDate="true"
                @date_updated="form.delivery_date = $event"
                :notBefore="deliveryDateLowerLimit"
                :notAfter="projectInfo.admin_end_date"
                :helper="$t('FORMS.HELPER.DELIVERY_DATE')"
                :disabled="!allowUpdate"
                :slim="true"
              >
              </InputGenericDatePicker>
            </div>
            <div class="row mb-15">
              <InputGeneric
                id="input-subject"
                :model="$v.form.subject"
                class="col-12"
                alignLabel="left"
                :placeholder="$t('FORMS.INPUTS.SUBJECT')"
                :label="$t('FORMS.INPUTS.SUBJECT')"
                :showInline="false"
                :submissionStates="submissionStates"
                :error="$t('FORMS.ERROR.SUBJECT')"
                :disabled="!allowUpdate"
              >
              </InputGeneric>
            </div>
            <div class="row">
              <InputGenericRichText
                class="col-xl-12"
                id="input-content"
                :model="$v.form.content"
                :submissionStates="submissionStates"
                :allowImages="true"
                :label="$t('FORMS.INPUTS.CONTENT')"
                :error="$t('FORMS.ERROR.CONTENT')"
                :disabled="!allowUpdate"
              >
              </InputGenericRichText>
            </div>
          </div>
        </div>
        <div
          v-if="loadingComplete"
          class="card-footer d-flex justify-content-between my-0 py-4"
        >
          <div class="d-flex justify-content-start">
            <router-link
              tag="a"
              class="btn btn-sm btn-secondary mx-1"
              :to="{ name: 'communicate_summary' }"
            >
              {{ $t("GENERAL.BUTTONS.CANCEL") }}
            </router-link>
            <button
              v-if="allowDelete"
              class="btn btn-sm btn-outline-danger mx-1"
              @click="onDelete()"
            >
              {{ $t("GENERAL.TEXT.DELETE") }}
            </button>
          </div>
          <div class="d-flex justify-content-end">
            <button
              v-if="allowUpdate"
              class="btn btn-sm btn-secondary mx-1"
              :disabled="!allowPreview"
              @click="onPreviewToggle"
            >
              {{ $t("GENERAL.BUTTONS.PREVIEW") }}
            </button>
            <button
              v-if="allowUpdate"
              class="btn btn-sm btn-primary mx-1"
              @click="onSave(0)"
            >
              {{ saveButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <EmailVariables
      :variables="projectCommunicationVariableList"
      ref="projectCommunicationVariablesModal"
    >
    </EmailVariables>

    <EmailPreview :form="form" ref="projectCommunicationPreviewModal">
    </EmailPreview>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from "vuex";
import PageLoader from "@/core/classes/PageLoader.js";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import { POPULATE_PROJECT_SEGMENT_LIST } from "@/core/services/store/project/project_segments.module";
import { POPULATE_PROJECT_PROFILES_LIST } from "@/core/services/store/project/project_profiles.module";
import {
  POPULATE_PROJECT_COMMUNICATIONS_TEMPLATES,
  POPULATE_PROJECT_COMMUNICATIONS_VARIABLES,
} from "@/core/services/store/project/project_communications.module";
import { POPULATE_ACTIVITY_LIST } from "@/core/services/store/activity/activity.module";
import { POPULATE_SYS_ADMINISTRATOR_LIST } from "@/core/services/store/core/user.module";
import { POPULATE_PROJECT_USERS_LIST } from "@/core/services/store/project/project_users.module";
import {
  POPULATE_PROJECT_COMMUNICATION_INFO,
  CLEAR_PROJECT_COMMUNICATION_ERRORS,
  CREATE_PROJECT_COMMUNICATION,
  UPDATE_PROJECT_COMMUNICATION,
  DELETE_PROJECT_COMMUNICATION,
} from "@/core/services/store/project/project_communications.module";

import ErrorBox from "@/view/components/ErrorBox";
import ParticipantFilter from "./ParticipantFilter.vue";
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericRichText from "@/view/components/form-inputs/GenericRichText";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";
import InputGenericDatePicker from "@/modules/together-helpers/components/generic-inputs/GenericDatePicker";
import EmailVariables from "@/view/content/modals/EmailVariables";
import EmailPreview from "@/view/content/modals/EmailPreview";
import EmailTemplateHelper from "@/helpers/EmailTemplateHelper";

export default {
  mixins: [validationMixin],

  name: "ManageCommunication",

  data() {
    return {
      pageLoader: new PageLoader(8),
      submissionStates: {
        submitting: false,
      },
      error: {
        active: false,
        message: "",
      },
      moaValidation: true,
      register_status: [1],
      registerStatus: [
        { value: 1, text: `${this.$t("GENERAL.STATUS.UNREGISTERED")}` },
        { value: 2, text: `${this.$t("GENERAL.STATUS.REGISTERED")}` },
      ],
      supportedTypes: [
        { id: 1, title: `${this.$t("SYSTEM.EMAIL_TYPE.1")}` },
        { id: 2, title: `${this.$t("SYSTEM.EMAIL_TYPE.2")}` },
        { id: 3, title: `${this.$t("SYSTEM.EMAIL_TYPE.3")}` },
        { id: 4, title: `${this.$t("SYSTEM.EMAIL_TYPE.4")}` },
        { id: 5, title: `${this.$t("SYSTEM.EMAIL_TYPE.5")}` },
        { id: 6, title: `${this.$t("SYSTEM.EMAIL_TYPE.6")}` },
        { id: 7, title: `${this.$t("SYSTEM.EMAIL_TYPE.7")}` },
        // { id: 8, title: `${this.$t("SYSTEM.EMAIL_TYPE.8")}`},
        // { id: 9, title: `${this.$t("SYSTEM.EMAIL_TYPE.9")}`},
      ],
      segmentationBreakdown: [],
      activityBreakdown: [],
      form: {
        draft: 0,
        include_branding: 1,
        type: 1,
        template_id: null,
        segments: [],
        users: [],
        activity_id: null,
        subject: "",
        content: "",
        user_id: null,
        delivery_date: "",
      },
    };
  },

  validations: {
    register_status: { required },
    form: {
      draft: { required },
      include_branding: { required },
      type: { required },
      template_id: {},
      segments: {},
      users: {},
      activity_id: {},
      subject: { required },
      content: { required },
      user_id: { required },
      delivery_date: {},
    },
  },

  components: {
    ParticipantFilter,
    InputGenericCheckbox,
    InputGeneric,
    InputGenericDropdown,
    InputGenericRichText,
    InputGenericDatePicker,
    ErrorBox,
    EmailVariables,
    EmailPreview,
  },

  mounted() {
    this.form.user_id = this.currentUser.id;

    this.fetchProjectSegmentations();
    this.fetchActivities();
    this.fetchProjectCommunityManagers();
    this.fetchProjectCommunicationTemplates();
    this.fetchProjectCommunicationVariables();
    this.fetchProjectCommunicateMOAStatus();
    this.fetchProjectProfiles();

    if (this.currentUser.relation_to_project == "super_admin") {
      this.fetchSystemAdmins();
    } else {
      this.pageLoader.componentsCompleted++;
    }

    if (this.projectCommunicationPreload.users != undefined) {
      this.form.type = 3;
      this.form.users = this.projectCommunicationPreload.users;
      this.setProjectCommunicationPreload();
    }

    if (this.managementType == "update") {
      this.fetchExistingCommunication();
    } else {
      this.pageLoader.setCompleteComponent();
    }
  },

  methods: {
    fetchProjectCommunicationTemplates: function () {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_COMMUNICATIONS_TEMPLATES, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project communication templates, please check back later or contact the helpdesk";
        });
    },
    fetchProjectCommunicationVariables: function () {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_COMMUNICATIONS_VARIABLES, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project communication variables, please check back later or contact the helpdesk";
        });
    },
    fetchActivities() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    fetchSystemAdmins: function () {
      let payload = {
        params: {
          paginate: false,
          read_only: 1,
        },
      };

      this.$store
        .dispatch(POPULATE_SYS_ADMINISTRATOR_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the system admins, please check back later or contact the helpdesk";
        });
    },
    fetchProjectCommunityManagers: function () {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          activeSegments: "with",
          invitedSegments: "with",
          x_filter_roles: [1, 2, 3],
          display_name: 'ASC'
        },
      };

      this.$store
        .dispatch(POPULATE_PROJECT_USERS_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the community managers, please check back later or contact the helpdesk";
        });
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.fetchProjectSegments();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
  },
    fetchProjectSegments() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_LIST, payload)
        .then(() => {
          this.mergeSegmentationsAndSegments();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segments list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectCommunicateMOAStatus: function () {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/project_emails/validate/moa`,
      };

      this.$store
        .dispatch(GENERIC_RESOURCE, payload)
        .catch(() => {
          this.moaValidation = false;
          this.scrollTop();
        })
        .finally(() => {
          this.pageLoader.setCompleteComponent();
        });
    },
    applyProjectCommunicationTemplate: function (
      templateId = null,
      oldTemplateId = null
    ) {
      if (templateId == "null") {
        return;
      } else {
        this.submissionStates.submitting = true;
        if (this.form.subject.length > 0 || this.form.content.length > 0) {
          EmailTemplateHelper.warnChange(this)
            .then((res) => {
              if (res == true) {
                EmailTemplateHelper.fetchContent(this, templateId).then(
                  (res) => {
                    this.form.subject = res.SUBJECT;
                    this.form.content = res.BODY;
                  }
                );
              } else {
                this.form.template_id = oldTemplateId;
              }
            })
            .finally(() => {
              this.submissionStates.submitting = false;
            });
        } else {
          EmailTemplateHelper.fetchContent(this, templateId)
            .then((res) => {
              this.form.subject = res.SUBJECT;
              this.form.content = res.BODY;
            })
            .finally(() => {
              this.submissionStates.submitting = false;
            });
        }
      }
    },
    mergeSegmentationsAndSegments() {
      let segmentationBreakdown = [];
      var i;
      for (i = 0; i < this.projectSegmentations.length; i++) {
        let segmentationId = this.projectSegmentations[i].id;
        let parentObject = {
          id: this.projectSegmentations[i].name,
          label: this.projectSegmentations[i].name,
        };
        let children = [];
        var j;
        for (j = 0; j < this.projectSegments.length; j++) {
          if (
            this.projectSegments[j].project_segmentation_id == segmentationId
          ) {
            let segmentId = this.projectSegments[j].id;
            let childObject = {
              id: segmentId,
              label: this.projectSegments[j].name,
            };
            children.push(childObject);
          }
        }
        parentObject.children = children;
        children.length == 0
          ? (parentObject.isDisabled = true)
          : (parentObject.isDisabled = false);
        segmentationBreakdown.push(parentObject);
      }
      this.segmentationBreakdown = segmentationBreakdown;
      this.pageLoader.setCompleteComponent();
    },
    fetchProjectProfiles() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          projectSegments: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_PROFILES_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project users list, please check back later or contact the helpdesk";
        });
    },
    onFilterResetChildren: function () {
      this.form.segments = [];
      this.form.users = [];
      this.form.activity_id = null;
    },
    onFilterReset: function () {
      this.form.type = 1;
      this.form.segments = [];
      this.form.users = [];
      this.form.activity_id = null;
    },
    clearErrors: function () {
      this.$store.dispatch(CLEAR_PROJECT_COMMUNICATION_ERRORS);
    },
    onDelete: function () {
      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
      };

      this.$store
        .dispatch(DELETE_PROJECT_COMMUNICATION, payload)
        .then(() => {
          this.$router.push({ name: "communicate_summary" });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    onSave: function () {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.scrollTop();
        return;
      }

      if (!EmailTemplateHelper.dataValidForTemplate(this.form)) {
        EmailTemplateHelper.alertTagsInvalid(this);
        return;
      }

      if (!EmailTemplateHelper.dateDoesNotContainPlaceholder(this.form)) {
        EmailTemplateHelper.alertPlaceholderPresent(this);
        return;
      }

      if (this.managementType == "update") {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    calculateRegisterStatus: function () {
      let displayArray = [1, 2, 6, 7];
      if (displayArray.includes(this.form.type)) {
        this.form.register_status = this.register_status;
      } else {
        this.form.register_status = null;
      }
    },
    submitPost: function () {
      this.submissionStates.submitting = true;
      this.calculateRegisterStatus();
      let payload = {
        projectId: this.projectInfo.id,
        data: this.form,
      };

      this.$store
        .dispatch(CREATE_PROJECT_COMMUNICATION, payload)
        .then(() => {
          this.$router.push({ name: "communicate_summary" });
        })
        .catch(() => {
          this.scrollTop();
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitUpdate: function () {
      this.submissionStates.submitting = true;
      this.calculateRegisterStatus();
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
        data: this.form,
      };

      this.$store
        .dispatch(UPDATE_PROJECT_COMMUNICATION, payload)
        .then(() => {
          this.$router.push({ name: "communicate_summary" });
        })
        .catch(() => {
          this.scrollTop();
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    fetchExistingCommunication: function () {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
      };
      this.$store
        .dispatch(POPULATE_PROJECT_COMMUNICATION_INFO, payload)
        .then(() => {
          this.form.draft = this.projectCommunicationInfo.draft;
          this.form.include_branding =
            this.projectCommunicationInfo.include_branding;
          this.form.subject = this.projectCommunicationInfo.subject;
          this.form.content = this.projectCommunicationInfo.content;
          switch (this.projectCommunicationInfo.register_status) {
            case 1:
              this.register_status = [1];
              break;
            case 2:
              this.register_status = [2];
              break;
            case 3:
              this.register_status = [1, 2];
              break;

            default:
              this.register_status = [1];
              break;
          }
          this.form.register_status =
            this.projectCommunicationInfo.register_status;
          this.form.user_id = this.projectCommunicationInfo.user_id;
          this.form.delivery_date =
            this.projectCommunicationInfo.delivery_date == null
              ? ""
              : this.projectCommunicationInfo.delivery_date;
          this.form.type = this.projectCommunicationInfo.type;
          this.form.segments = this.projectCommunicationInfo.pluckedSegments;
          this.form.activities =
            this.projectCommunicationInfo.pluckedActivities;
          this.form.users = this.projectCommunicationInfo.pluckedRecipients;
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the current communication, please check back later or contact the helpdesk";
        });
    },
    onVariablesToggle: function () {
      this.$refs.projectCommunicationVariablesModal.$refs.modal.show();
    },
    onPreviewToggle: function () {
      if (!EmailTemplateHelper.dataValidForTemplate(this.form)) {
        EmailTemplateHelper.alertTagsInvalid(this);
        return;
      }

      if (!EmailTemplateHelper.dateDoesNotContainPlaceholder(this.form)) {
        EmailTemplateHelper.alertPlaceholderPresent(this);
        return;
      }

      this.$refs.projectCommunicationPreviewModal.$refs.modal.show();
    },
    scrollTop: function () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    ...mapMutations({
      setProjectCommunicationPreload: "setProjectCommunicationPreload"
    })
  },

  watch: {
    "form.type": {
      handler() {
        if (this.loadingComplete) {
          this.onFilterResetChildren();
        }
      },
    },
    "form.template_id": {
      handler(newVal, oldVal) {
        if (this.submissionStates.submitting == false) {
          this.applyProjectCommunicationTemplate(newVal, oldVal);
        }
      },
    },
  },

  computed: {
    ...mapGetters([
      "userList",
      "projectUsers",
      "projectInfo",
      "projectSegmentations",
      "projectSegments",
      "projectProfiles",
      "activityList",
      "currentUser",
      "projectCommunicationTemplateList",
      "projectCommunicationVariableList",
      "projectCommunicationInfo",
      "projectCommunicationErrors",
      "projectCommunicationPreload"
    ]),
    loadingComplete: function () {
      return this.pageLoader.getStatus();
    },
    deliveryDateLowerLimit: function () {
      var currentdate = new Date();
      var datetime =
        currentdate.getFullYear() +
        "-" +
        ("0" + (currentdate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentdate.getDate()).slice(-2) +
        " " +
        "00:00:00";

      return datetime;
    },
    managementType: function () {
      if (this.$route.params.id != undefined) {
        return "update";
      } else {
        return "post";
      }
    },
    allowUpdate: function () {
      if (
        this.communicationStage.status != "sent" &&
        this.communicationStage.status != "outbox"
      ) {
        return true;
      } else {
        return false;
      }
    },
    allowDelete: function () {
      if (
        this.communicationStage.status == "draft" ||
        this.communicationStage.status == "scheduled"
      ) {
        return true;
      } else {
        return false;
      }
    },
    communicationStage: function () {
      let stage = {};

      if (this.managementType == "post") {
        stage = {
          status: "new",
          text: `${this.$t("SYSTEM.SENT_STATUS.NEW")}`,
          class: "primary",
        };
      } else {
        if (this.projectCommunicationInfo.status.int == 2) {
          stage = {
            status: "draft",
            text: `${this.$t("SYSTEM.SENT_STATUS.DRAFT")}`,
            class: "danger",
          };
        } else if (this.projectCommunicationInfo.status.int == 3) {
          stage = {
            status: "scheduled",
            text: `${this.$t("SYSTEM.SENT_STATUS.SCHEDULED")}`,
            class: "primary",
          };
        } else if (this.projectCommunicationInfo.status.int == 4) {
          stage = {
            status: "sent",
            text: `${this.$t("SYSTEM.SENT_STATUS.SENT")}`,
            class: "success",
          };
        } else if (this.projectCommunicationInfo.status.int == 5) {
          stage = {
            status: "outbox",
            text: `${this.$t("SYSTEM.SENT_STATUS.OUTBOX")}`,
            class: "warning",
          };
        }
      }

      return stage;
    },
    senderHelper: function () {
      return `${this.$t("FORMS.HELPER.SENDER")}` + process.env.VUE_APP_NO_REPLY;
    },
    calculatedSaveButton: function () {
      if (this.form.delivery_date != null) {
        return `${this.$t("GENERAL.BUTTONS.SCHEDULE_SAVE")}`;
      } else {
        return `${this.$t("GENERAL.BUTTONS.SAVE")}`;
      }
    },
    calculatedUserList: function () {
      const self = this;

      let admins = this.userList.filter(function (obj) {
        return obj.id == self.currentUser.id || obj.id == self.form.user_id;
      });

      let userList = this.projectUsers.concat(admins);

      return userList;
    },
    progressionType: function () {
      let deliveryDate = new Date(this.form.deliveryDate);
      let currentDate = new Date();

      if (parseInt(this.form.draft) == 1) {
        return "DRAFT";
      } else {
        if (
          this.form.delivery_date.length == 0 ||
          this.form.delivery_date == null ||
          deliveryDate.getTime() > currentDate.getTime()
        ) {
          return "SEND";
        } else {
          return "SCHEDULE";
        }
      }
    },
    saveButtonText: function () {
      let text = "";

      switch (this.progressionType) {
        case "DRAFT":
          text = `${this.$t("GENERAL.BUTTONS.SAVE_DRAFT")}`;
          break;
        case "SEND":
          text = `${this.$t("GENERAL.BUTTONS.SAVE_SEND")}`;
          break;
        case "SCHEDULE":
          text = `${this.$t("GENERAL.BUTTONS.SCHEDULE_SAVE")}`;
          break;
      }

      return text;
    },
    calculatedDropdownPlaceholder: function () {
      if (this.projectCommunicationTemplateList.length == 0) {
        return `${this.$t("FORMS.PLACEHOLDER.EMAIL_TEMPLATE_NONE_AVAILABLE")}`;
      } else {
        return `${this.$t("FORMS.PLACEHOLDER.EMAIL_TEMPLATE")}`;
      }
    },
    allowPreview: function () {
      return this.form.content.length > 1 && this.form.subject.length > 1
        ? true
        : false;
    },
    profileBreakdown() {
      let profileBreakdown = [];
      this.projectProfiles.forEach(function (profile) {
        let obj = {};
        obj.id = profile.user.id;
        obj.label = profile.user.display_name;
        obj.role = profile.system_project_role_id;
        obj.segments = profile.project_segments;

        profileBreakdown.push(obj);
      });

      this.calculatedUserList.forEach(function (user) {
        if (_.findIndex(profileBreakdown, { id: user.id }) == -1) {
          let obj = {};
          obj.id = user.id;
          obj.label = user.display_name;
          if (!_.isEmpty(user.active_project_role)) {
            obj.role = user.active_project_role[0].id;
          } else if (!_.isEmpty(user.invited_project_role)) {
            obj.role = user.invited_project_role[0].id;
          } else {
            obj.role = null
          }
          if (!_.isEmpty(user.active_segments)) {
            obj.segments = user.active_segments;
          } else if (!_.isEmpty(user.invited_segments)) {
            obj.segments = user.invited_segments;
          } else{
            obj.segments = null;
          }
          profileBreakdown.push(obj);
        }
      });

      profileBreakdown = profileBreakdown.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

      return profileBreakdown;
    },
  },
};
</script>
